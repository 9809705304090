<template>
  <div class="Device">
    <simple-c-u-r-d
      search-url="/api/constructionDevice/list"
      add-or-update-url="/api/constructionDevice/addOrUpdate"
      delete-url="/api/constructionDevice/delete"
      :show-form="showForm"
      :model="addForm"
      :add-form-rules="addFormRules"
      is-pageable
      label-width="120px"
      :format-commit-data="formatCommitData"
      :show-add-button="()=>show"
      :show-delete-button="()=>show"
      :show-edit-button="()=>show"
      :show-operation="()=>show"
    />
  </div>
</template>

<script>
import SimpleCURD from '@/components/simple/SimpleCURD'
import ArrayUtils from '@/common/js/ArrayUtils'
export default {
  name: 'Device',
  components: { SimpleCURD },
  inject: ['user', 'formatCommitData'],
  data() {
    return {
      showForm: [
        { param: 'name', name: '设备名称', maxlength: '100', search: true, clearable: true },
        {
          param: 'inspectDeviceId',
          name: '设备类型',
          search: true,
          type: 'select',
          clearable: true,
          option: () => this.device,
          formatter: (row) => ArrayUtils.getArrayObject(this.device, 'value', row.inspectDeviceId)?.label,
        },
        {
          param: 'haveSerialNo',
          name: '序列号',
          type: 'radio',
          radio: [{ label: '存在', value: 1 }, { label: '不存在', value: 0 }],
          clearable: true,
          formatter: (row) => {
            switch (row.haveSerialNo) {
            case 0: return '不存在'
            case 1: return '存在'
            }
          }
        },
      ],
      addForm: { name: null, type: null, inspectDeviceId: null, haveSerialNo: null },
      addFormRules: {
        name: [{ required: true, message: '请输入设备名称', trigger: 'blur' }],
        inspectDeviceId: [{ required: true, message: '请选择查勘设备类型', trigger: 'blur' }],
        haveSerialNo: [{ required: true, message: '请选择是否有序列号', trigger: 'blur' }],
      },
      device: [],
      show: null
    }
  },
  created() {
    this.getInspectDevice()
  },
  mounted() {
    const auth = this.user().role.authority.split(',')
    if (!auth.includes('AUTH_DEVICE')) {
      this.show = false
    } else {
      this.show = true
    }
  },
  methods: {
    getInspectDevice() {
      this.$get('/api/inspectDevice/getAll').then(result => {
        this.device = result.data.map(item => {
          return {
            label: item.name,
            value: item.id,
          }
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
